import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlatformIcon = _resolveComponent("PlatformIcon")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "trigger", { open: _ctx.showModal }),
    _createVNode(_component_a_modal, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
      centered: true,
      title: _ctx.title,
      class: _normalizeClass(['confirm-modal', _ctx.modalClass])
    }, {
      closeIcon: _withCtx(() => [
        _createVNode(_component_PlatformIcon, {
          name: "CloseIcon",
          class: "confirm-modal__close-icon"
        })
      ]),
      footer: _withCtx(() => [
        (_ctx.$slots['custom-footer'])
          ? _renderSlot(_ctx.$slots, "custom-footer", { key: 0 })
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_a_button, {
                onClick: _ctx.handleOk,
                type: "primary",
                danger: _ctx.type === _ctx.ConfirmModalTypes.danger
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.confirmText), 1)
                ]),
                _: 1
              }, 8, ["onClick", "danger"]),
              _createVNode(_component_a_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.cancelText), 1)
                ]),
                _: 1
              })
            ], 64))
      ]),
      default: _withCtx(() => [
        (_ctx.$slots['custom-body'])
          ? _renderSlot(_ctx.$slots, "custom-body", { key: 0 })
          : (_openBlock(), _createBlock(_component_a_typography_paragraph, { key: 1 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.body), 1)
              ]),
              _: 1
            }))
      ]),
      _: 3
    }, 8, ["visible", "title", "class"])
  ], 64))
}