import { computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { authSessionsGql } from "@/api/sessions/authSessions";
import { AuthSessions_authSessions_AuthSessionResults } from "@/api/sessions/__generated__/AuthSessions";
import { terminateSessionGql } from "@/api/sessions/terminateSession";
import {
  TerminateSession_terminateSession_AuthSession,
  TerminateSessionVariables,
  TerminateSession,
} from "@/api/sessions/__generated__/TerminateSession";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import isEmpty from "lodash/isEmpty";

export const useAuthSessions = (fetchOnMount = true) => {
  /**
   * GET AUTH SESSIONS
   */
  const {
    result: authSessionsResult,
    loading: authSessionLoading,
    refetch: refetchAuthSession,
  } = useQuery(authSessionsGql, {}, () => ({
    enabled: fetchOnMount,
    fetchPolicy: "network-only",
  }));

  const authSessionsList = computed(() => {
    const parsedResponse =
      parseGqlResponse<AuthSessions_authSessions_AuthSessionResults>(
        "AuthSessionResults",
        authSessionsResult.value
      );

    return parsedResponse.data?.results || [];
  });
  /**
   * END GET AUTH SESSIONS
   */

  // TERMINATE SESSION
  const {
    mutate: terminateAuthSessionMutation,
    loading: terminateAuthSessionLoading,
  } = useCustomMutation<TerminateSession, TerminateSessionVariables>(
    terminateSessionGql
  );

  const handleTerminateAuthSession = async ({ authSessionId }) => {
    const terminateAuthSessionResponse = await terminateAuthSessionMutation({
      input: {
        authSessionId,
      },
    });

    const parsedResponse =
      parseGqlResponse<TerminateSession_terminateSession_AuthSession>(
        "AuthSession",
        terminateAuthSessionResponse
      );

    console.log("terminateAuthSessionResponse:parsedResponse", parsedResponse);

    if (
      !isEmpty(parsedResponse.error?.errors) ||
      !terminateAuthSessionResponse
    ) {
      throw new Error("Failed to terminate auth session");
    }

    return parsedResponse.data;
  };
  // END TERMINATE SESSION

  return {
    refetchAuthSession,
    authSessionsList,
    authSessionLoading,
    handleTerminateAuthSession,
    terminateAuthSessionLoading,
  };
};
