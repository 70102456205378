import gql from "graphql-tag";

export const authSessionsGql = gql`
  query AuthSessions {
    authSessions {
      ... on AuthSessionResults {
        results {
          id
          twoFactorAuthenticated
          deviceId
          countryCode {
            name
            code
            alpha3
            numeric
            iocCode
          }
          device
          browser
          os
          authToken {
            created
            expiry
          }
          isCurrent
          lastActive
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
