
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import ConfirmModal, {
  ConfirmModalTypes,
} from "@/shared/components/Modals/ConfirmModal.vue";

export default defineComponent({
  emits: ["on-auth-revoke-request"],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t, ConfirmModalTypes };
  },
  components: { CommonCardLayout, ConfirmModal },
});
