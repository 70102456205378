import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { purgeInboxGql } from "@/api/settings/purgeInbox";
import {
  PurgeInbox,
  PurgeInboxVariables,
  PurgeInbox_purgeInbox_GenericSuccess,
} from "@/api/settings/__generated__/PurgeInbox";
import isEmpty from "lodash/isEmpty";

export const usePurgeInbox = () => {
  const { t } = useI18n();
  /**
   * PURGE INBOX
   */
  const { mutate, loading: loadingPurgeInbox } = useCustomMutation<
    PurgeInbox,
    PurgeInboxVariables
  >(purgeInboxGql);

  const purgeInbox = async (otpCode) => {
    const purgeInboxResponse = await mutate({
      otpCode,
    });
    const parsedResponse =
      parseGqlResponse<PurgeInbox_purgeInbox_GenericSuccess>(
        "GenericSuccess",
        purgeInboxResponse
      );

    console.log("purgeInbox:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !purgeInboxResponse) {
      throw new Error("Failed to purge inbox");
    }
    message.info(t("Inbox has been purged successfully!"));
  };
  /**
   * END PURGE INBOX
   */

  return {
    loadingPurgeInbox,
    purgeInbox,
  };
};
