
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import SettingsAuthSessions from "./SettingsAuthSessions.vue";
import SettingsTwoFactorSetup from "./SettingsTwoFactorSetup.vue";
import SettingsChangePassword from "./SettingsChangePassword.vue";
import SettingsDangerZone from "./SettingsDangerZone.vue";
import { use2FASetup } from "@/shared/composables/use2FASetup";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import { useChangePassword } from "@/shared/composables/useChangePassword";
import { usePurgeAccount } from "@/shared/composables/danger/usePurgeAccount";
import { usePurgeInbox } from "@/shared/composables/danger/usePurgeInbox";
import { useStore } from "vuex";
// import { UserRegistrationMethod } from "../../../../__generated__/globalTypes";
import { useAuthSessions } from "@/shared/composables/Sessions/useAuthSessions";
import { AuthSessions_authSessions_AuthSessionResults_results } from "@/api/sessions/__generated__/AuthSessions";
import attempt from "lodash/attempt";
import { useAccount } from "@/shared/composables/Profile/useAccount";

export default defineComponent({
  components: {
    PageLayout,
    SettingsTwoFactorSetup,
    SettingsChangePassword,
    SettingsDangerZone,
    SettingsAuthSessions,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const settingsChangePasswordRef = ref();
    const settingsDangerZoneRef = ref();
    const { isExternalRegistration } = useAccount();

    const clearForgotPasswordOTP = () => {
      const clearInput =
        settingsChangePasswordRef.value?.confirmModalRef?.inputOtpRef
          ?.clearInput;
      if (!clearInput) {
        console.error("clearForgotPasswordOTP is undefined");
      } else {
        attempt(clearInput);
      }
    };

    const clearPurgeInboxOTP = () => {
      const clearInput =
        settingsDangerZoneRef.value?.refPurgeInboxConfirmOTPModal?.clearInput;
      if (!clearInput) {
        console.error("clearPurgeInboxOTP in undefined");
      } else {
        attempt(clearInput);
      }
    };

    const clearRemoveAccountOTP = () => {
      const clearInput =
        settingsDangerZoneRef.value?.refRemoveAccountConfirmOTPModal
          ?.clearInput;
      if (!clearInput) {
        console.error("clearRemoveAccountOTP in undefined");
      } else {
        attempt(clearInput);
      }
    };

    const { handle2faRevoke, revoke2faLoading } = use2FASetup();
    const { handleChangePassword, changePasswordLoading } = useChangePassword();

    const { loadingRemoveAccount, handleRemoveAccount } = usePurgeAccount();
    const { purgeInbox, loadingPurgeInbox } = usePurgeInbox();

    // Auth Sessions
    const {
      authSessionsList,
      authSessionLoading,
      handleTerminateAuthSession,
      terminateAuthSessionLoading,
    } = useAuthSessions();

    const removeAccountHandler = async (otpCode: string) => {
      try {
        await handleRemoveAccount(otpCode);
      } catch (error) {
        console.log(error);
        clearRemoveAccountOTP();
      }
    };

    const handlePurgeInbox = async (otpCode: string) => {
      try {
        await purgeInbox(otpCode);
        if (settingsDangerZoneRef.value.handlePurgeInboxModalClose) {
          settingsDangerZoneRef.value.handlePurgeInboxModalClose();
        }
      } catch (error) {
        console.log(error);
        clearPurgeInboxOTP();
      }
    };

    const twoFactorRevoke = async () => {
      try {
        await handle2faRevoke();
        await router.push({
          name: routeNames.settingsTwoFactorAuthentication,
          params: {
            action: "revoked",
          },
        });
      } catch (error) {
        console.log(error);
      }
    };

    const handleTerminateSession = async (
      authSession?: AuthSessions_authSessions_AuthSessionResults_results
    ) => {
      try {
        // Call API
        await handleTerminateAuthSession({ authSessionId: authSession?.id });

        /**
         * If there's no authSessionId, it means terminate all session
         */
        if (!authSession || authSession.isCurrent) {
          await store.dispatch("logoutSuccess");
          await router.push({ name: routeNames.login });
        }
      } catch (error) {
        console.log("Error on Settings: terminate Auth Sessions", error);
      }
    };

    return {
      t,
      loadingPurgeInbox,
      handlePurgeInbox,
      loadingRemoveAccount,
      handleRemoveAccount,
      removeAccountHandler,
      twoFactorRevoke,
      revoke2faLoading,
      handleChangePassword,
      changePasswordLoading,
      settingsChangePasswordRef,
      settingsDangerZoneRef,
      isExternalRegistration,
      handleChangePasswordRequest: async (formLoginState) => {
        try {
          await handleChangePassword(formLoginState);
          // close modal on success
          settingsChangePasswordRef.value.handleCloseModal();
          // reset form value
          settingsChangePasswordRef.value.resetForm();
        } catch (error) {
          console.log(error);
          clearForgotPasswordOTP();
        }
      },
      authSessionsList,
      handleTerminateSession,
      authSessionLoading,
      terminateAuthSessionLoading,
    };
  },
});
