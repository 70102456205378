
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import {
  makePasswordRule,
  makeNewPasswordRule,
} from "@/shared/utils/validators/passwordValidator";
import InputPassword from "@/shared/components/Forms/InputPassword.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import { useGetFormErrorFields } from "@/shared/composables/form/useGetFormErrorFields";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import ConfirmOTPModal from "@/shared/components/Modals/ConfirmOTPModal.vue";

export default defineComponent({
  emits: ["on-change-password-request"],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, { emit }) {
    const { t } = useI18n();
    const router = useRouter();
    const confirmModalRef = ref();
    const formConnect = useGetFormErrorFields();

    const changePasswordForm = reactive({
      oldPassword: "",
      newPassword: "",
    });

    const handleCloseModal = () => {
      console.log("handleCloseModal", confirmModalRef.value);
      if (confirmModalRef.value && confirmModalRef.value.handleCloseModal) {
        confirmModalRef.value.handleCloseModal();
      }
    };

    const resetForm = () => {
      changePasswordForm.oldPassword = "";
      changePasswordForm.newPassword = "";
    };

    return {
      t,
      confirmModalRef,
      formConnect,
      changePasswordForm,
      handleCloseModal,
      handleConfirmChange: (otpCode) => {
        console.log("handleConfirmChange:otpCode", otpCode);
        // On Confirm Change, emit on-change-password-request
        emit("on-change-password-request", { ...changePasswordForm, otpCode });
      },
      handleBackupCode: () => {
        router.push({ name: routeNames.backupCode });
      },
      resetForm,
    };
  },
  methods: { makeRequiredRule, makePasswordRule, makeNewPasswordRule },
  components: {
    CommonCardLayout,
    InputPassword,
    FormGroup,
    ConfirmOTPModal,
  },
});
