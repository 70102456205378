import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputPassword = _resolveComponent("InputPassword")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_ConfirmOTPModal = _resolveComponent("ConfirmOTPModal")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createBlock(_component_CommonCardLayout, {
    title: _ctx.t('Change password')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ConfirmOTPModal, {
        ref: "confirmModalRef",
        title: _ctx.t('Change Password'),
        body: 
        _ctx.t(
          'To change the password, please confirm by entering the six-digit code from the Authenticator app'
        )
      ,
        confirmText: _ctx.t('Confirm Change'),
        onConfirm: _ctx.handleConfirmChange,
        loading: _ctx.loading
      }, {
        trigger: _withCtx(({ open }) => [
          _createVNode(_component_FormGroup, {
            class: "settings-change-layout-form",
            "wrapper-col": { span: 24 },
            model: _ctx.changePasswordForm,
            layout: "vertical",
            loading: _ctx.loading,
            disabled: _ctx.disabled,
            onFinish: open,
            onFinishFailed: _ctx.formConnect.finishFailed
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InputPassword, {
                label: "Old password",
                name: "oldPassword",
                placeholder: "********",
                value: _ctx.changePasswordForm.oldPassword,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.changePasswordForm.oldPassword) = $event)),
                rules: [_ctx.makeRequiredRule(_ctx.t('Old password is required'))]
              }, null, 8, ["value", "rules"]),
              _createVNode(_component_InputPassword, {
                label: "New password",
                name: "newPassword",
                placeholder: "********",
                value: _ctx.changePasswordForm.newPassword,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.changePasswordForm.newPassword) = $event)),
                rules: [
              _ctx.makeRequiredRule(_ctx.t('New password is required')),
              _ctx.makePasswordRule(
                _ctx.t(
                  'At least 8 characters, lower and upper case letters (a-z), numbers (0-9)'
                )
              ),
              _ctx.makeNewPasswordRule(
                _ctx.changePasswordForm.oldPassword,
                _ctx.t('New password cannot be the same as your old password.')
              ),
            ],
                extra: 
              _ctx.formConnect.fieldHasError('newPassword')
                ? ''
                : _ctx.t(
                    'At least 8 characters, lower and upper case letters (a-z), numbers (0-9)'
                  )
            
              }, null, 8, ["value", "rules", "extra"]),
              _createVNode(_component_a_button, {
                type: "primary",
                "html-type": "submit",
                class: "mt-6",
                disabled: _ctx.disabled || _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Save")), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 2
          }, 1032, ["model", "loading", "disabled", "onFinish", "onFinishFailed"])
        ]),
        _: 1
      }, 8, ["title", "body", "confirmText", "onConfirm", "loading"])
    ]),
    _: 1
  }, 8, ["title"]))
}