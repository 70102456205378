import gql from "graphql-tag";

export const changePasswordGql = gql`
  mutation ChangePassword($input: ChangePasswordInput!, $otpCode: String!) {
    changePassword(input: $input, otpCode: $otpCode) {
      ... on GenericSuccess {
        success
        message
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
