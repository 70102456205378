import gql from "graphql-tag";

export const purgeInboxGql = gql`
  mutation PurgeInbox($otpCode: String!) {
    purgeInbox(otpCode: $otpCode) {
      ... on GenericSuccess {
        success
        message
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
