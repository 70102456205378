import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { computed } from "vue";
import { message } from "ant-design-vue";
import { apiErrorCodes } from "@/shared/utils/constants";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { changePasswordGql } from "@/api/onboarding/changePassword";
import {
  ChangePassword,
  ChangePasswordVariables,
  ChangePassword_changePassword_GenericSuccess,
} from "@/api/onboarding/__generated__/ChangePassword";
import { i18nTranslate } from "@/plugins/i18n";
import isEmpty from "lodash/isEmpty";

export function useChangePassword() {
  const changePasswordMutation = useCustomMutation<
    ChangePassword,
    ChangePasswordVariables
  >(changePasswordGql);

  const handleChangePassword = async ({
    oldPassword,
    newPassword,
    otpCode,
  }) => {
    const changePasswordResponse = await changePasswordMutation.mutate({
      input: {
        oldPassword,
        newPassword,
      },
      otpCode,
    });

    const parsedResponse =
      parseGqlResponse<ChangePassword_changePassword_GenericSuccess>(
        "GenericSuccess",
        changePasswordResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("parsedResponse", parsedResponse);

    /**
     * Handle errors
     */
    if (!isEmpty(parsedResponse.error?.errors) || !changePasswordResponse) {
      throw new Error("Failed to change password");
    }

    if (parsedResponse.data?.success) {
      /**
       * Do not log the user out after changing the password.
       */
      message.info(i18nTranslate("Password changed successfully! "));
    }
  };

  return {
    handleChangePassword,
    changePasswordLoading: computed(() => changePasswordMutation.loading.value),
  };
}
