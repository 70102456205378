import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_SettingsAuthSessionItem = _resolveComponent("SettingsAuthSessionItem")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createBlock(_component_CommonCardLayout, {
    title: _ctx.t('Active sessions'),
    class: "auth-sessions"
  }, {
    "title-right": _withCtx(() => [
      _createVNode(_component_ConfirmModal, {
        title: _ctx.t('Session termination'),
        body: _ctx.t('This will terminate the session and you will be logged out.'),
        "confirm-text": _ctx.t('Yes, terminate session'),
        "cancel-text": _ctx.t('Keep'),
        type: _ctx.ConfirmModalTypes.danger,
        onOk: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-terminate-session')))
      }, {
        trigger: _withCtx(({ open }) => [
          _createVNode(_component_a_button, {
            size: "small",
            class: "px-2",
            danger: "",
            disabled: _ctx.loading,
            onClick: open
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Terminate all")), 1)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ]),
        _: 1
      }, 8, ["title", "body", "confirm-text", "cancel-text", "type"])
    ]),
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, {
            key: 0,
            class: "px-4 pt-4",
            title: false,
            active: "",
            paragraph: { rows: 4 },
            loading: _ctx.loading
          }, null, 8, ["loading"]))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.authSessionsList, (authSession) => {
            return (_openBlock(), _createBlock(_component_ConfirmModal, {
              key: authSession.id,
              title: _ctx.t('Session termination'),
              body: 
          authSession.isCurrent
            ? _ctx.t(
                'This will terminate your current session and you will be logged out. Do you confirm?'
              )
            : _ctx.t('Are you sure you want to terminate the session?')
        ,
              "confirm-text": _ctx.t('Yes, terminate session'),
              "cancel-text": _ctx.t('Keep'),
              type: _ctx.ConfirmModalTypes.danger,
              onOk: ($event: any) => (_ctx.$emit('on-terminate-session', authSession))
            }, {
              trigger: _withCtx(({ open }) => [
                _createVNode(_component_SettingsAuthSessionItem, {
                  disabled: _ctx.loading,
                  "auth-session": authSession,
                  onOnTerminateSession: open
                }, null, 8, ["disabled", "auth-session", "onOnTerminateSession"])
              ]),
              _: 2
            }, 1032, ["title", "body", "confirm-text", "cancel-text", "type", "onOk"]))
          }), 128))
    ]),
    _: 1
  }, 8, ["title"]))
}