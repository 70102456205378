
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import ArrowIcon from "@/assets/icons/arrow.svg";
import { AuthSessions_authSessions_AuthSessionResults_results } from "@/api/sessions/__generated__/AuthSessions";
import isFuture from "date-fns/isFuture";
import { formatDatePretty } from "@/shared/utils/date";

export default defineComponent({
  props: {
    authSession: {
      type: Object as PropType<AuthSessions_authSessions_AuthSessionResults_results>,
      required: true,
    },
  },
  emits: ["on-terminate-session"],
  setup(props) {
    const { t } = useI18n();

    /**
     * If expiry date is future date or null, Session is Active
     */

    const isActive = computed(
      () =>
        props.authSession?.authToken &&
        (props.authSession?.authToken?.expiry == null ||
          isFuture(new Date(props.authSession?.authToken?.expiry)))
    );

    return {
      t,
      ArrowIcon,
      isActive,
    };
  },
  methods: { formatDatePretty },
});
