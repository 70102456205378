import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { purgeAccountGql } from "@/api/twoFactorAuthentication/purgeAccount";
import {
  PurgeAccount,
  PurgeAccountVariables,
  PurgeAccount_purgeAccount_GenericSuccess,
} from "@/api/twoFactorAuthentication/__generated__/PurgeAccount";
import { apiErrorCodes } from "@/shared/utils/constants";
import isEmpty from "lodash/isEmpty";
import { useStore } from "vuex";
import { computed } from "vue";

export const usePurgeAccount = () => {
  const { t } = useI18n();
  const router = useRouter();
  const store = useStore();
  /**
   * DELETE Account
   */
  const purgeAccountMutation = useCustomMutation<
    PurgeAccount,
    PurgeAccountVariables
  >(purgeAccountGql);
  const handleRemoveAccount = async (otpCode) => {
    const handleRemoveAccountResponse = await purgeAccountMutation.mutate({
      otpCode,
    });
    const parsedResponse =
      parseGqlResponse<PurgeAccount_purgeAccount_GenericSuccess>(
        "GenericSuccess",
        handleRemoveAccountResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("handleRemoveAccount:parsedResponse", parsedResponse);

    if (
      !isEmpty(parsedResponse.error?.errors) ||
      !handleRemoveAccountResponse
    ) {
      throw new Error("Failed to delete account.");
    }
    message.info(t("The account has been successfully deleted!"));

    await store.dispatch("logoutSuccess");
    await router.push({ name: routeNames.register });
  };
  /**
   * END DELETE
   */

  return {
    loadingRemoveAccount: computed(() => purgeAccountMutation.loading.value),
    handleRemoveAccount,
  };
};
