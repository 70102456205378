import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsTwoFactorSetup = _resolveComponent("SettingsTwoFactorSetup")!
  const _component_SettingsAuthSessions = _resolveComponent("SettingsAuthSessions")!
  const _component_SettingsChangePassword = _resolveComponent("SettingsChangePassword")!
  const _component_SettingsDangerZone = _resolveComponent("SettingsDangerZone")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    title: _ctx.t('Settings')
  }, {
    default: _withCtx(() => [
      (!_ctx.isExternalRegistration)
        ? (_openBlock(), _createBlock(_component_SettingsTwoFactorSetup, {
            key: 0,
            class: "mb-8",
            loading: _ctx.revoke2faLoading,
            disabled: 
        _ctx.changePasswordLoading ||
        _ctx.loadingPurgeInbox ||
        _ctx.loadingRemoveAccount ||
        _ctx.authSessionLoading ||
        _ctx.terminateAuthSessionLoading
      ,
            onOnAuthRevokeRequest: _ctx.twoFactorRevoke
          }, null, 8, ["loading", "disabled", "onOnAuthRevokeRequest"]))
        : _createCommentVNode("", true),
      _createVNode(_component_SettingsAuthSessions, {
        class: "mb-8",
        "auth-sessions-list": _ctx.authSessionsList,
        loading: _ctx.authSessionLoading || _ctx.terminateAuthSessionLoading,
        disabled: 
        _ctx.changePasswordLoading ||
        _ctx.revoke2faLoading ||
        _ctx.loadingPurgeInbox ||
        _ctx.loadingRemoveAccount
      ,
        onOnTerminateSession: _ctx.handleTerminateSession
      }, null, 8, ["auth-sessions-list", "loading", "disabled", "onOnTerminateSession"]),
      (!_ctx.isExternalRegistration)
        ? (_openBlock(), _createBlock(_component_SettingsChangePassword, {
            key: 1,
            ref: "settingsChangePasswordRef",
            class: "mb-8",
            loading: _ctx.changePasswordLoading,
            disabled: 
        _ctx.revoke2faLoading ||
        _ctx.loadingPurgeInbox ||
        _ctx.loadingRemoveAccount ||
        _ctx.authSessionLoading ||
        _ctx.terminateAuthSessionLoading
      ,
            onOnChangePasswordRequest: _ctx.handleChangePasswordRequest
          }, null, 8, ["loading", "disabled", "onOnChangePasswordRequest"]))
        : _createCommentVNode("", true),
      _createVNode(_component_SettingsDangerZone, {
        ref: "settingsDangerZoneRef",
        "loading-purge-inbox": _ctx.loadingPurgeInbox,
        "loading-remove-account": _ctx.loadingRemoveAccount,
        "disabled-inbox-purge": 
        _ctx.loadingRemoveAccount ||
        _ctx.revoke2faLoading ||
        _ctx.changePasswordLoading ||
        _ctx.authSessionLoading ||
        _ctx.terminateAuthSessionLoading
      ,
        "disabled-remove-account": 
        _ctx.loadingPurgeInbox ||
        _ctx.revoke2faLoading ||
        _ctx.changePasswordLoading ||
        _ctx.authSessionLoading ||
        _ctx.terminateAuthSessionLoading
      ,
        "is-external-registration": _ctx.isExternalRegistration,
        onOnPurgeInboxRequest: _ctx.handlePurgeInbox,
        onOnRemoveAccountRequest: _ctx.removeAccountHandler
      }, null, 8, ["loading-purge-inbox", "loading-remove-account", "disabled-inbox-purge", "disabled-remove-account", "is-external-registration", "onOnPurgeInboxRequest", "onOnRemoveAccountRequest"])
    ]),
    _: 1
  }, 8, ["title"]))
}