
import { i18nTranslate } from "@/plugins/i18n";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import PlatformIcon from "@/shared/components/Icons/PlatformIcon.vue";

export enum ConfirmModalTypes {
  // eslint-disable-next-line no-unused-vars
  confirm = "confirm",
  // eslint-disable-next-line no-unused-vars
  danger = "danger",
}

export default defineComponent({
  props: {
    title: {
      type: String,
      default: i18nTranslate("Confirm"),
    },
    body: {
      type: String,
      default: i18nTranslate("Are you sure?"),
    },
    modalClass: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: ConfirmModalTypes.confirm,
    },
    confirmText: {
      type: String,
      default: i18nTranslate("Yes, confirm"),
    },
    cancelText: {
      type: String,
      default: i18nTranslate("Cancel"),
    },
  },
  emits: ["ok"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const visible = ref<boolean>(false);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = () => {
      emit("ok");
      visible.value = false;
    };

    return {
      t,
      ConfirmModalTypes,
      visible,
      showModal,
      handleOk,
    };
  },
  components: { PlatformIcon },
});
