import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ConfirmOTPModal = _resolveComponent("ConfirmOTPModal")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createBlock(_component_CommonCardLayout, {
    title: _ctx.t('Danger zone'),
    type: "danger"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ConfirmOTPModal, {
        ref: "refPurgeInboxConfirmOTPModal",
        type: "danger",
        title: _ctx.t('Inbox Purging'),
        body: 
        _ctx.isExternalRegistration
          ? _ctx.t('All messages in your inbox will be removed.\n\nDo you confirm?')
          : _ctx.t(
              'All messages in your inbox will be removed\n\nTo confirm, please enter the six-digit code from the authenticator app.'
            )
      ,
        loading: _ctx.loadingPurgeInbox,
        "hide-o-t-p-input": _ctx.isExternalRegistration,
        onConfirm: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-purge-inbox-request', $event)))
      }, {
        trigger: _withCtx(({ open }) => [
          _createVNode(_component_a_button, {
            size: "small",
            type: "primary",
            danger: "",
            class: "mb-2",
            loading: _ctx.loadingPurgeInbox,
            disabled: _ctx.disabledInboxPurge,
            onClick: open
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Purge Inbox")), 1)
            ]),
            _: 2
          }, 1032, ["loading", "disabled", "onClick"])
        ]),
        _: 1
      }, 8, ["title", "body", "loading", "hide-o-t-p-input"]),
      _createVNode(_component_a_typography_paragraph, { class: "small mb-4" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("This will remove all messages in your Inbox.")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ConfirmOTPModal, {
        ref: "refRemoveAccountConfirmOTPModal",
        type: "danger",
        title: _ctx.t('Account removing'),
        body: 
        _ctx.isExternalRegistration
          ? _ctx.t(
              'This will terminate all sessions and delete all data from your account, including the Inbox messages, communication channels, history, and the account itself. This action is permanent and cannot be reversed.\n\nDo you confirm?'
            )
          : _ctx.t(
              'This will terminate all sessions and delete all data from your account, including the Inbox messages, communication channels, history, and the account itself. This action is permanent and cannot be reversed.\n\nTo confirm, please enter the six-digit code from the Authenticator app.'
            )
      ,
        loading: _ctx.loadingRemoveAccount,
        "hide-o-t-p-input": _ctx.isExternalRegistration,
        onConfirm: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-remove-account-request', $event)))
      }, {
        trigger: _withCtx(({ open }) => [
          _createVNode(_component_a_button, {
            size: "small",
            type: "primary",
            danger: "",
            class: "mb-2",
            loading: _ctx.loadingRemoveAccount,
            disabled: _ctx.disabledRemoveAccount,
            onClick: open
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Remove account")), 1)
            ]),
            _: 2
          }, 1032, ["loading", "disabled", "onClick"])
        ]),
        _: 1
      }, 8, ["title", "body", "loading", "hide-o-t-p-input"]),
      _createVNode(_component_a_typography_paragraph, { class: "small" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t(
          "This will terminate all sessions and data from your account, including the Inbox messages, communication channels, history, and the account itself. Use with caution as we wipe all your data and cannot restore it later."
        )), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}