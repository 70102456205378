import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createBlock(_component_CommonCardLayout, {
    title: _ctx.t('Auth')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_typography_paragraph, { class: "m-0 flex-1" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Dual factor authentication")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ConfirmModal, {
          title: _ctx.t('Revoke'),
          body: _ctx.t('Are you sure you want to revoke this device?'),
          "confirm-text": _ctx.t('Yes, revoke'),
          type: _ctx.ConfirmModalTypes.danger,
          onOk: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-auth-revoke-request')))
        }, {
          trigger: _withCtx(({ open }) => [
            _createVNode(_component_a_button, {
              type: "default",
              size: "small",
              onClick: open,
              loading: _ctx.loading,
              disabled: _ctx.disabled
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Revoke")), 1)
              ]),
              _: 2
            }, 1032, ["onClick", "loading", "disabled"])
          ]),
          _: 1
        }, 8, ["title", "body", "confirm-text", "type"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}