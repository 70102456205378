import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = {
  key: 2,
  class: "ml-6 leading-none"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_alert = _resolveComponent("a-alert")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex justify-center items-center p-4 active-session-item", { 'cursor-pointer': _ctx.isActive }]),
    disabled: _ctx.$attrs.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isActive && _ctx.$emit('on-terminate-session')))
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_a_typography_text, { class: "font-bold" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Browser")) + " " + _toDisplayString("  ·  ") + _toDisplayString(_ctx.authSession?.browser), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_a_typography_text, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.authSession?.countryCode?.name), 1)
          ]),
          _: 1
        })
      ])
    ]),
    (_ctx.isActive)
      ? (_openBlock(), _createBlock(_component_a_alert, {
          key: 0,
          class: "py-0 px-2",
          message: _ctx.t('Active'),
          type: "success",
          banner: "",
          "show-icon": false
        }, null, 8, ["message"]))
      : (_openBlock(), _createBlock(_component_a_typography_text, {
          key: 1,
          class: "small"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.formatDatePretty(
          !_ctx.authSession?.authToken
            ? _ctx.authSession?.lastActive
            : _ctx.authSession.authToken.expiry
        )), 1)
          ]),
          _: 1
        })),
    (_ctx.isActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.ArrowIcon,
            alt: "arrow"
          }, null, 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}