
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import ConfirmOTPModal from "@/shared/components/Modals/ConfirmOTPModal.vue";

export default defineComponent({
  components: {
    CommonCardLayout,
    ConfirmOTPModal,
  },
  props: {
    loadingPurgeInbox: {
      type: Boolean,
      default: false,
    },
    disabledInboxPurge: {
      type: Boolean,
      default: false,
    },
    loadingRemoveAccount: {
      type: Boolean,
      default: false,
    },
    disabledRemoveAccount: {
      type: Boolean,
      default: false,
    },
    isExternalRegistration: Boolean,
  },
  emits: ["on-purge-inbox-request", "on-remove-account-request"],
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const refPurgeInboxConfirmOTPModal = ref();
    const refRemoveAccountConfirmOTPModal = ref();

    const formState = reactive({
      otpCode: "",
    });

    const handlePurgeInboxModalClose = () => {
      /**
       * Manually close purge inbox modal
       */
      if (
        refPurgeInboxConfirmOTPModal.value &&
        refPurgeInboxConfirmOTPModal.value.handleCloseModal
      ) {
        refPurgeInboxConfirmOTPModal.value.handleCloseModal();
      }
    };

    return {
      t,
      formState,
      handleBackupCode: () => {
        router.push({ name: routeNames.backupCode });
      },
      refPurgeInboxConfirmOTPModal,
      refRemoveAccountConfirmOTPModal,
      handlePurgeInboxModalClose,
    };
  },
});
