import gql from "graphql-tag";

export const terminateSessionGql = gql`
  mutation TerminateSession($input: TerminateSessionInput!) {
    terminateSession(input: $input) {
      ... on AuthSession {
        id
        twoFactorAuthenticated
        deviceId
        countryCode {
          name
          code
          alpha3
          numeric
          iocCode
        }
        device
        browser
        os
        authToken {
          created
          expiry
        }
        isCurrent
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
