
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import ArrowIcon from "@/assets/icons/arrow.svg";
import ConfirmModal, {
  ConfirmModalTypes,
} from "@/shared/components/Modals/ConfirmModal.vue";
import SettingsAuthSessionItem from "@/web/views/Settings/SettingsAuthSessionItem.vue";
import { AuthSessions_authSessions_AuthSessionResults } from "@/api/sessions/__generated__/AuthSessions";

export default defineComponent({
  components: {
    CommonCardLayout,
    ConfirmModal,
    SettingsAuthSessionItem,
  },
  emits: ["on-terminate-session"],
  props: {
    authSessionsList: Array as PropType<
      AuthSessions_authSessions_AuthSessionResults["results"]
    >,
    loading: Boolean,
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
      ArrowIcon,
      ConfirmModalTypes,
    };
  },
});
