import { ref } from "vue";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

type AntErrorField = {
  errors: string[];
  name: string[];
  warnings?: string[];
};

/**
 * Composable that returns list of fields with error or check if field has invalid value
 *
 * @example <FormGroup @finishFailed="finishFailed"></FormGroup>
 */
export function useGetFormErrorFields() {
  const errorFields = ref<string[]>([]);

  const finishFailed = (val) => {
    if (val && !isEmpty(val.errorFields)) {
      let newFields: string[] = [];
      map(val.errorFields, (field: AntErrorField) => {
        newFields = [...newFields, ...field.name];
      });
      errorFields.value = newFields;
    }
  };

  const fieldHasError = (fieldName) => {
    return errorFields.value.some((field) => field === fieldName);
  };

  return {
    errorFields,
    finishFailed,
    fieldHasError,
  };
}

export default useGetFormErrorFields;
